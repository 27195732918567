import React from "react";
import { Link } from "react-router-dom";
import {
  format,
  truncateNumber,
  formatDuration,
} from "../../../utils";
import styles from "./InfoCard.module.css";

export default function InfoCard({
  ind,
  length,
  data,
  image,
  actionText = "Go to item",
  linkUrl,
}) {
  const operatorName =
    data?.operator?.split("@")?.[0] || data?.run?.operator || "Unknown";

  return (
    <div
          className={`${styles.container} ${styles[data.runStatus]}`}
    >
      <img src={image} className={styles.image} alt="" />
      <div className={styles.subContainer}>
        <h2 className={styles.title}>Run #{data?.displayId} </h2>
        <div className={styles.subContainerTimes}>
          <span className="text-neutral-400 text-sm font-normal leading-tight">
            Date :{" "}
          </span>
          <span className="text-neutral-600 text-sm font-normal leading-tight">
            {format(data?.startTimeUtc, "M/d/yy")}
            <br />
          </span>
        </div>
        <div
          className={
            styles.subContainerStats + " " + "bg-stone-50 rounded-2xl w-full"
          }
        >
          <span className="whitespace-nowrap">
            <span className="text-zinc-500 text-sm font-normal leading-tight">
              Operator :{" "}
            </span>
            <span className="text-black text-sm font-normal leading-tight">
              {operatorName}
              <br />
            </span>
          </span>
          <span className="text-zinc-500 text-sm font-normal leading-tight">
            Location :{" "}
          </span>
          <span className="text-black text-sm font-normal leading-tight">
            {data?.location?.name}
            <br />
          </span>
          <span className="text-zinc-500 text-sm font-normal leading-tight">
            Total Area :{" "}
          </span>
          <span className="text-black text-sm font-normal leading-tight">
            {truncateNumber(data?.totalAreaSprayedAcres, 1)} acres
            <br />
          </span>
          <span className="text-zinc-500 text-sm font-normal leading-tight">
            Herbicide Sprayed :{" "}
          </span>
          <span className="text-black text-sm font-normal leading-tight">
            {truncateNumber(data?.totalPesticideSprayedGallons)} gallons
            <br />
          </span>
          <span className="text-zinc-500 text-sm font-normal leading-tight">
            Avg Speed :{" "}
          </span>
          <span className="text-black text-sm font-normal leading-tight">
            {truncateNumber(data?.avgSpeedMph)} mph
            <br />
          </span>
          <span className="text-zinc-500 text-sm font-normal leading-tight">
            Duration :{" "}
          </span>
          <span className="text-black text-sm font-normal leading-tight">
            {formatDuration(data?.duration)}
            <br />
          </span>
          <span className="text-md font-extrabold">
            <span className="text-zinc-500 leading-tight text-[#8dc34d]">
              Herbicide Saved :{" "}
            </span>
            <span className="text-black text-base leading-tight">
              {truncateNumber(data?.herbicideSavedPercent)}%
              <br />
            </span>
          </span>
        </div>
      </div>
      {linkUrl ? (
        <Link to={linkUrl} className="w-full">
          <button className={styles.button}>{actionText}</button>
        </Link>
      ) : (
        <button className={styles.button}>{actionText}</button>
      )}
    </div>
  );
}
