import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  TextField,
} from "@fluentui/react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import authService from "../../../api-authorization/AuthorizeService";

const AddNozzleDialog = ({ clientId, tractorId, onDismiss, onSuccess }) => {
  const { control, handleSubmit } = useForm();

  const handleChange = (event) => {
    const value = event.target.value;
    if (value < 0) {
      event.target.value = 0;
    }
    if (event.target.name === "numberToAdd" && value < 1) {
      event.target.value = 1;
    }
    if (event.target.name === "overlap" && value > 100) {
      event.target.value = 100;
    }
  };

  const addNozzles = async (data) => {
    const token = await authService.getAccessToken();
    const n = data.numberToAdd;
    delete data.numberToAdd;
    const body = Array.from({ length: n }, () => ({
      ...data,
      overlap: data.overlap / 100,
      clientId: clientId,
      tractorId: tractorId,
    }));
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzles`,

      {
        method: "POST",
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
        body: JSON.stringify(body),
      }
    );
    toast.success("Nozzles added successfully!");
    onSuccess();
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Add Nozzle(s)",
        closeButtonAriaLabel: "Close",
        subText:
          "Add nozzles from left to right when looking at the boom from behind the sprayer.",
      }}
    >
      <form onSubmit={handleSubmit(addNozzles)}>
        <Controller
          control={control}
          name="numberToAdd"
          defaultValue="1"
          render={({ field }) => (
            <TextField
              label="Number To Add"
              type="number"
              {...field}
              onChange={(e) => {
                handleChange(e);
                field.onChange(e);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="nozzleGpm"
          defaultValue="0"
          render={({ field }) => (
            <TextField
              label="Nozzle GPM"
              type="number"
              {...field}
              onChange={(e) => {
                handleChange(e);
                field.onChange(e);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="sprayWidth"
          defaultValue="0"
          render={({ field }) => (
            <TextField
              label="Spray Width (in)"
              type="number"
              {...field}
              onChange={(e) => {
                handleChange(e);
                field.onChange(e);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="overlap"
          defaultValue={0}
          render={({ field }) => (
            <TextField
              label="Overlap (%)"
              type="number"
              {...field}
              onChange={(e) => {
                handleChange(e);
                field.onChange(e);
              }}
            />
          )}
        />
        <DialogFooter>
          <DefaultButton type="submit" text="Add Nozzles" />
          <DefaultButton onClick={onDismiss} text="Cancel" />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default AddNozzleDialog;
