import React from "react";
import { Link } from "react-router-dom";
import InfoCard from "./InfoCard";
import { farm1, farm2, farm3, farm4 } from "../../images";
import styles from "../pages/TractorDetails/TractorDetails.module.css";

const images = [farm1, farm2, farm3, farm4];

function TractorDetailsItems({ currentItems, length, tractorName, tractorId }) {
  return (
    <>
      {currentItems &&
        currentItems.map((run, ind) => {

          let actionText = "";
          let linkUrl = `/precision-spray/${tractorName}/${tractorId}/run-details/${run?.id}/${run.displayId}`;
          if (run.runStatus === "Syncing") {
            actionText = "Syncing";
          } else if (run.runStatus === "Running") {
            actionText = "In Progress";
          } else {
            actionText = "Run Details";
          }

          return (
            <Link to={linkUrl} key={run.id} className={styles.infoCardLink}>
              <InfoCard
                image={images[Math.floor(Math.random() * images.length)]}
                data={run}
                ind={run.index}
                length={length}
                actionText={actionText}
              />
            </Link>
          );
        })}
    </>
  );
}

export default TractorDetailsItems;
