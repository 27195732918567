import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
} from "@fluentui/react";
import toast from "react-hot-toast";
import authService from "../../../api-authorization/AuthorizeService";

const DeleteNozzleDialog = ({ clientId, nozzleId, onDismiss, onSuccess }) => {
  const deleteNozzle = async () => {
    const token = await authService.getAccessToken();
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzle?clientId=${clientId}&nozzleId=${nozzleId}`,
      {
        method: "DELETE",
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
      }
    );
    toast.success("Nozzle deleted successfully!");
    onSuccess();
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Delete Nozzle",
        closeButtonAriaLabel: "Close",
        subText: "Are you sure you want to delete this nozzle?",
      }}
    >
      <DialogFooter>
        <DefaultButton onClick={deleteNozzle} text="Delete Nozzle" />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default DeleteNozzleDialog;
