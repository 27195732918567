import React, { useState, useEffect, useMemo, useRef } from "react";
import authService from "../../api-authorization/AuthorizeService";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import useSWR, { mutate } from "swr";
import ReactPaginate from "react-paginate";
import RelButton from "../../components/RelButton";
import { ActionIcon } from "@mantine/core";
import RequestOverlay from "../../components/RequestOverlay";
import { addicon } from "../../../images/index";
import tractor from "../../../images/tractorreal.png";
import NavCard from "../../components/NavCard";
import TractorDetailsItems from "../../components/TractorDetailsItems";
import { IconRefresh } from "@tabler/icons-react";
import toast from "react-hot-toast";
import styles from "./TractorDetails.module.css";
import { format } from "../../../utils";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { setPage } from "../../../store/paginationSlice";
import StartRunDialog from "../../components/StartRunDialog/StartRunDialog";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GRAPH_RUN_COUNT = 8;
const ITEMS_PER_PAGE = 8;

function createGradient(
  ctx,
  area,
  colorStart = "#1B531A",
  colorMid = "#46AB42",
  colorEnd = "#46AB42"
) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Herbicide Sprayed Bar Chart",
    },
  },

  onHover: (event, chartElement) => {
    document.documentElement.style.cursor = chartElement[0]
      ? "pointer"
      : "default";
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },

    y: {
      grid: {
        // display: false,
        color: "rgba(217,143,7,0.1)",
      },

      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          const toDisplay = [
            ticks[0].value,
            ticks[Math.floor(ticks.length / 2)].value,
            ticks[ticks.length - 1].value,
          ];
          return toDisplay.includes(value) ? value : "";
        },
      },
    },
  },
};

export default function TractorDetails() {
  const { id: tractorId, name: tractorName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedPage = useSelector(
    (state) =>
      state.pagination.runPagesTracking.find((i) => i.tractorId === tractorId)
        ?.page || 0
  );
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [runInProgress, setRunInProgress] = useState(null);
  const [startRunDialog, showStartRunDialog] = useState(false);

  // Pagination
  const [itemOffset, setItemOffset] = useState(storedPage ? storedPage * 8 : 0);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const currentItems = data?.runs?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data?.runs?.length / ITEMS_PER_PAGE) || 0;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % data.runs.length;
    dispatch(setPage({ tractorId, page: event.selected }));
    setItemOffset(newOffset);
    document
      .querySelector("#list-of-runs")
      .scrollIntoView({ behavior: "smooth" });
  };

  const {
    data: response,
    error,
    isLoading,
  } = useSWR(`api/tractors?tractorId=${tractorId}`);

  useEffect(() => {
    if (!response || response.length === 0) return;
    const indRuns = response.runs.map((i, ind) => ({ ...i, index: ind }));
    setData({ ...response, runs: indRuns });
    const activeRun = response.runs.find((run) => run.runStatus === "Running")
    if (activeRun) {
      setRunInProgress(activeRun);
    }
  }, [response]);

  const fetchTractorDetails = () => {
    mutate(`api/tractors?tractorId=${tractorId}`);
  };

  const barChartData = useMemo(() => {
    if (!currentItems || currentItems.length === 0) return null;

    const chart = chartRef.current;

    if (!chart) {
      return null;
    }

    let chartData = {
      labels: [],
      datasets: [
        {
          barPercentage: 0.5,
          categoryPercentage: 1,
          maintainAspectRatio: false,
          aspectRatio: 1,
          responsive: true,
          label: "",
          data: [],
          backgroundColor: createGradient(chart.ctx, chart.chartArea),
          hoverBackgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            "#0a7506",
            "#4ec44b",
            "#4ec44b"
          ),
          borderRadius: 100,
        },
      ],
    };

    const numberOfRuns =
      currentItems.length >= GRAPH_RUN_COUNT
        ? GRAPH_RUN_COUNT
        : currentItems.length;

    for (let index = numberOfRuns - 1; index >= 0; index--) {
      chartData.labels.push(`Run ${currentItems[index].displayId}`);
      chartData.datasets[0].label = `Sprayed Herbicide (gallons)`;
      chartData.datasets[0].data.push(
        currentItems[index].totalPesticideSprayedGallons
      );
    }

    // chartData.labels = chartData.labels.reverse();
    chartData.datasets[0].data = chartData.datasets[0].data;
    return chartData;
  }, [currentItems, chartRef]);

  const handleBarChartClick = (event) => {
    const ind = getElementAtEvent(chartRef.current, event)?.[0]?.index;

    if (!ind || ind === -1) return;
    const originalIndex = currentItems.length - 1 - ind;
    navigate(
      `/precision-spray/${tractorName}/${tractorId}/run-details/${currentItems[originalIndex].id}/${currentItems[originalIndex].displayId}`
    );
  };

  const shouldDisplayChart =
    barChartData &&
    barChartData?.labels?.length > 0 &&
    barChartData?.datasets?.length > 0;

  const operatorName =
    data?.run?.operator.split("@")?.[0] || data?.run?.operator || "...";

  const hasRunInProgress = data?.runs?.findIndex((run) => run.runStatus === "Running") != -1;

  const startRunDialogClosed = (run_details) => {
    showStartRunDialog(false);
    if (run_details) {
        toast.success("Tractor run started successfully!");
        setTimeout(() => {
            navigate(
                `/precision-spray/${tractorName
                    .replace(" ", "-")
                    .toLowerCase()}/${tractorId}/${run_details}`
            );
        }, 1500);
    }
  };

  const handleStartRun = async (event) => {
    showStartRunDialog(true);
  }

  return (
    <div className={styles.container}>
      <RequestOverlay
        isLoading={isLoading}
        error={error}
        id="loading-overlay"
        errorTitle="Your data couldn't be loaded."
        errorSubtitle="Please contact an administrator."
      />

      <div className=" bg-white rounded-2xl shadow">
        <div className={styles.chartContainer}>
          {shouldDisplayChart && (
            <h3 className={styles.tractorTitle}>{data?.tractor?.name}</h3>
          )}
          <div
            className={
              styles.chartContents +
              ` ${shouldDisplayChart ? "block" : "opacity-0 absolute"}`
            }
          >
            <Bar
              ref={chartRef}
              options={options}
              data={barChartData || { labels: [], datasets: [] }}
              onClick={handleBarChartClick}
            />
          </div>

          {!shouldDisplayChart && !isLoading && (
            <div className="max-w-[50rem] flex flex-col mx-auto w-full h-full">
              <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
                {/* <h1 className="block text-7xl font-bold text-gray-800 sm:text-9xl">404</h1> */}
                <h1 className="block text-2xl font-bold text-white" />
                <p className="font-extrabold">
                  {error
                    ? "Oops, something went wrong."
                    : "No data to display!"}
                </p>

                <p className="font-extrabold">
                  {error ? "We had trouble loading the data." : null}
                </p>

                {error && (
                  <ActionIcon
                    onClick={fetchTractorDetails}
                    disabled={isLoading}
                    color="teal"
                    size="xl"
                    radius="xl"
                    className="m-auto mt-3"
                  >
                    <IconRefresh size="2.125rem" />
                  </ActionIcon>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
        <div className={styles.bgContainer} id="list-of-runs">        
        {currentItems && currentItems.length > 0 ? (
          <div className={styles.gridContainer}>
            <TractorDetailsItems
              currentItems={currentItems}
              length={data?.runs?.length}
              tractorName={tractorName}
              tractorId={tractorId}
            />
          </div>
        ) : (
          <div className={styles.gridContainer}></div>
        )}

        <div className={styles.paginateContainer}>
          <ReactPaginate
            forcePage={storedPage}
            breakLabel="..."
            breakClassName="relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            breakLinkClassName="px-4 py-2"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
            pageLinkClassName="px-4 py-2"
            pageClassName="relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            activeClassName="bg-[#8dc34d] hover:bg-[#8dc34d] text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 text-black bg-white"
            activeLinkClassName="  bg-[#031832] text-white"
            nextLabel={<RelButton arrowDirection="right" />}
            previousLabel={<RelButton arrowDirection="left" />}
            disableInitialCallback
          />
        </div>
      </div>
    </div>
  );
}
