import React, { useState, useEffect, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import { useParams, useNavigate } from "react-router-dom";
import to from "await-to-js";
import { Stack, Text } from "@fluentui/react";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { ActionIcon } from "@mantine/core";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import authService from "../../api-authorization/AuthorizeService";
import StatCard from "../../components/StatCard";
import RequestOverlay from "../../components/RequestOverlay";
import {
  format,
  mergeClasses,
  getCoordinatesFromPolygonText,
} from "../../../utils";
import styles from "./RunDetails.module.css";
import Map from "../../components/Map";
import RunDevices from "../RunDevices";
import { selectRouteById } from "../../../store/runSlice";
import { cliparttractor } from "../../../images";

function getColor(value) {
  switch (value) {
    case "Completed":
      return "text-green-200";
    case "Syncing":
      return "text-blue-900";
    case "Running":
      return "text-yellow-600";

    default:
      return "text-white";
  }
}

const Title = ({ content }) => (
  <div className={styles.break}>
    <span style={{ position: "relative" }}>
      {content}
      <ActionIcon
        style={{ pointerEvents: "all" }}
        variant="transparent"
        size="xs"
        radius="xl"
        aria-label="Settings"
        className="statcardTooltip"
      >
        <IconInfoCircleFilled
          style={{
            width: "85%",
            height: "85%",
            color: "#FFF",
          }}
          stroke={1.5}
        />
      </ActionIcon>
    </span>{" "}
    <span className={styles.invisible}>........</span>
  </div>
);

function formatHeartbeatStalenessTime(time) {
  if (!time) return;
  const split = time.split(":");
  if (!split || split.length != 2) return;
  const hours = String(Math.floor(split[0] / 60));
  const minutes = String(split[0] % 60);
  const seconds = String(split[1]);
  return `${hours}h ${minutes}m ${seconds}s`;
}

const RunDetails = () => {
  const { mutate } = useSWRConfig();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: ["visualization"],
  });

  const { runId, runIndex, tractorId } = useParams();
  const { data, error, isLoading } = useSWR(`/api/runs?runId=${runId}`);
  const [subLocations, setSubLocations] = useState([]);
  const [locationData, setLocationData] = useState(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState("all");

  const validSubLocations = useMemo(
    () =>
      subLocations.filter((subLocation) =>
        data.subRuns.some((subRun) => subLocation.id === subRun.subLocationId)
      ),
    [data, subLocations]
  );

  const subLocationStats = useMemo(() => {
    if (!selectedSubLocation || !data) return;
    if (selectedSubLocation === "all") {
      return data.run;
    }

    return data.subRuns.find(
      (i) => String(i.subLocationId) === String(selectedSubLocation)
    );
  }, [selectedSubLocation, data]);

  const polygons = useMemo(() => {
    // if (!subLocationData || subLocationData.length === 0) return [];
    // return subLocationData
    // .map((subLocation) => getCoordinates(subLocation.boundaries))
    // .filter(Boolean);
    if (!subLocations) return [];

    if (selectedSubLocation === "all") {
      return subLocations
        .map((i) => ({
          name: i.name,
          paths: getCoordinatesFromPolygonText(i.boundaries),
        }))
        .filter(Boolean);
    } else {
      const foundBlock = subLocations.find(
        (i) => String(i.id) === String(selectedSubLocation)
      );
      if (!foundBlock) return [];
      return [
        {
          name: foundBlock.name,
          paths: getCoordinatesFromPolygonText(foundBlock?.boundaries),
        },
      ];
    }
  }, [selectedSubLocation, subLocations]);

  console.log("polygons", polygons);

  const [activeMarker, setActiveMarker] = useState(null);
  const navigate = useNavigate();

  const runDataFromRedux = useSelector(selectRouteById(runId));

  const [markerType, setMarkerType] = React.useState(
    runDataFromRedux?.markerType || "spraydensity-heatmap"
  );

  const [isAiHeatmap, setIsAiHeatmap] = React.useState(
    runDataFromRedux?.isAiHeatmap || true
  );

  const markers = useMemo(() => {
    const result = [];

    for (let i = 0; i < data?.imageCaptures?.length; i++) {
      var imageCapture = data.imageCaptures[i];
      var images = imageCapture.image ? [imageCapture] : [];

      result.push({
        id: i + 1,
        images: images,
        eventDateUtc: imageCapture.eventDateUtc,
        position: {
          lat: imageCapture.lat,
          lng: imageCapture.long,
        },
      });
    }
    return result;
  }, [data]);

  const solenoidOpenEvents = useMemo(() => {
    const result = [];

    for (let i = 0; i < data?.events?.length; i++) {
      var solenoidEvent = data.events[i];

      result.push({
        lat: solenoidEvent.lat,
        lng: solenoidEvent.long,
      });
    }
    return result;
  }, [data]);

  const uniqueAiDiscoveries = useMemo(() => {
    const result = {};

    for (let i = 0; i < data?.imageCaptures?.length; i++) {
      var imageCapture = data.imageCaptures[i];
      if (imageCapture.aiDiscoveries) {
        for (let j = 0; j < imageCapture.aiDiscoveries?.length; j++) {
          let aiDiscovery = imageCapture.aiDiscoveries[j].trim();
          if (aiDiscovery in result) {
            result[aiDiscovery] += 1;
          } else {
            result[aiDiscovery] = 1;
          }
        }
      }
    }

    const sortedResponses = Object.fromEntries(
      Object.entries(result).sort(([, a], [, b]) => b - a)
    );

    return sortedResponses;
  }, [data]);

  const route = useMemo(() => {
    if (!data?.route || data.route.length === 0) return [];

    const result = [];

    for (let i = 0; i < data.route.length; i++) {
      result.push({
        lat: data.route[i].lat,
        lng: data.route[i].long,
        speedMph: data.route[i].speedMph,
      });
    }

    return result;
  }, [data]);

  const flow = useMemo(() => {
    if (!data?.flowData || data.flowData.length === 0) return [];

    const result = [];

    for (let i = 0; i < data.flowData.length; i++) {
      result.push({
        lat: data.flowData[i].lat,
        lng: data.flowData[i].long,
        pulses: data.flowData[i].pulses,
      });
    }

    return result;
  }, [data]);

  useEffect(() => {
    if (!data) return;

    const fetchLocation = async () => {
      try {
        const token = await authService.getAccessToken();
        const headers = {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        };
        const promises = [
          fetch(
            `${process.env.REACT_APP_BASE_URL}/api/locations?locationId=${data.run.locationId}`,
            headers
          ),
          fetch(
            `${process.env.REACT_APP_BASE_URL}/api/sublocations?locationId=${data.run.locationId}`,
            headers
          ),
        ];

        const [err, [locationData, subLocationData]] = await to(
          Promise.all(promises)
        );
        if (err) return alert("There was an error!");

        setLocationData(await locationData.json());
        const newBlocks = await subLocationData.json();
        setSubLocations(newBlocks.filter((i) => i.boundaries));
        // setSubLocationData(await subLocationData.json());
      } catch (error) {}
    };
    fetchLocation();
  }, [data]);

  const formattedTime = (time) => {
    if (!time) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h" + " " + splitTime[1] + "m";
  };

  const operatorName =
    data?.run?.operator?.split("@")?.[0] || data?.run?.operator || "Unknown";

  const statData = useMemo(() => {
    return [
      {
        title: <Title content="Herbicide Saved" />,
        value: subLocationStats?.herbicideSavedPercent,
        subtitle: "percent",
        animate: true,
        tooltip: (
          <>
            <img
              alt=""
              src={cliparttractor}
              width={200}
              height={"auto"}
              style={{ margin: "0 auto" }}
            />
            This represents the difference between the amount of chemical
            sprayed using precision technology versus the amount that would have
            been sprayed on full broadcast at the supplied application rate.
          </>
        ),
      },
      {
        title: <Title content="Coverage Area" />,
        value: subLocationStats?.coverageAreaSprayedAcres,
        subtitle: "acres",
        animate: true,
        tooltip:
          "This represents the width of the potential spray area multiplied by the distance driven. The width of the potential spray area is calculated by summing the spray width of each nozzle.",
      },
      {
        title: <Title content="Application Rate" />,
        value: subLocationStats?.applicationRateGalAcre,
        subtitle: "gal / acre",
        animate: true,
        tooltip:
          "This is set in the settings of the sprayer.  It is used in the calculation of Herbicide sprayed.",
      },
      {
        title: <Title content="Total Area" />,
        value: subLocationStats?.totalAreaSprayedAcres,
        subtitle: "acres",
        animate: true,
        tooltip:
          "This represents the width of the boom multiplied by the distance driven.  The width of the boom is calculated from the furthest left part of the boom to the furthest right part of the boom.",
      },
      {
        title: <Title content="Herbicide Sprayed" />,
        value: subLocationStats?.totalPesticideSprayedGallons,
        subtitle: "gallons",
        animate: true,
        tooltip:
          "This is the actual amount of chemical sprayed as reported by the flow meter.",
      },
      {
        title: <Title content="Weed Density" />,
        value: subLocationStats?.weedDensity,
        subtitle: "percent",
        animate: true,
        tooltip:
          "This represents the percentage of weed clusters that make up the area covered.",
      },

      {
        title: <Title content="Application Speed" />,
        value: subLocationStats?.avgSpeedMph,
        subtitle: "mph",
        animate: true,
        tooltip: "This represents the average speed during spray application.",
      },

      {
        title: <Title content="Application Date" />,
        animate: true,
        tooltip: "This represents the date the run started.",
        value: `${
          data?.run?.startTimeUtc
            ? format(new Date(data?.run?.startTimeUtc), "M/d")
            : ""
        }`,
        subtitle: `${
          data?.run?.startTimeUtc
            ? format(new Date(data?.run?.startTimeUtc), "yyyy")
            : ""
        }`,
      },
      {
        title: <Title content="Application Duration" />,
        tooltip:
          "This represents the total amount of running time for the day.",
        animate: true,
        value: formattedTime(subLocationStats?.duration),
        statSubtitleClassName: styles.statSubtitle,
        slotClassname: "margin-separation",
        subtitle: `time`,
      },
    ];
  }, [subLocationStats]);

  const { data: deviceStatus } = useSWR(
    `/api/tractors/device-status?tractorId=${tractorId}`
  );

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={isLoading}
          error={error}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />

        <section style={{ width: "100%", zIndex: 2 }}>
          <div
            className={mergeClasses(
              "grid  grid-cols-[fit-content(10ch)_1fr] gap-[20px]",
              styles.gridContainer
            )}
          >
            <main style={{ zIndex: 10 }}>
              <div
                className={
                  styles.responsiveStatCard +
                  " max-w-xl lg:max-w-3xl run-details-container m-auto"
                }
                style={{ zIndex: 2 }}
              >
                <h1
                  className={
                    styles.title +
                    " text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl"
                  }
                  style={{ "margin-bottom": "26px" }}
                >
                  Run # {data?.run?.displayId} Details -{" "}
                  {selectedSubLocation === "all"
                    ? "All blocks selected"
                    : `Block ${
                        subLocations.find(
                          (block) =>
                            String(block.id) === String(selectedSubLocation)
                        )?.name
                      }`}
                </h1>
                <div>
                  <div className="messages">
                    {data &&
                    data.run.runStatus === "Running" &&
                    deviceStatus?.connected !== deviceStatus?.total ? (
                      <div className="text-[#c9c920]">
                        <span class="material-icons warning">warning</span>
                        <span>
                          Connecting to devices ({deviceStatus.connected} of{" "}
                          {deviceStatus.total} connected.)
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <RunDevices />

                  <StatCard data={statData} />

                  <StatCard
                    className="mt-8"
                    textAreaClassName={styles.textArea}
                    gridClassName={
                      subLocationStats?.runStatus != "Running"
                        ? ""
                        : styles.gridTwoFifty
                    }
                    data={[
                      {
                        title: <Title content="Operator" />,
                        animate: false,
                        tooltip: "This represents the driver of the run.",
                        value: operatorName,
                        valueClassName: styles.smallerWordWrap,
                        subtitle: locationData ? locationData.name : "...",
                        statSubtitleClassName: styles.statSubtitleClassName,
                        className: styles.smallPadding,
                      },
                      {
                        title: (
                          <div className={styles.break}>
                            <span style={{ position: "relative" }}>
                              Status
                              <ActionIcon
                                style={{ pointerEvents: "all" }}
                                variant="transparent"
                                size="xs"
                                radius="xl"
                                aria-label="Settings"
                                className="statcardTooltip"
                              >
                                <IconInfoCircleFilled
                                  style={{
                                    width: "85%",
                                    height: "85%",
                                    color: "#FFF",
                                  }}
                                  stroke={1.5}
                                />
                              </ActionIcon>
                            </span>{" "}
                            <span className={styles.invisible}>........</span>
                          </div>
                        ),
                        animate: false,
                        tooltip:
                          "This can be one of three values\n Running – The run is live, an operator is currently driving the field \n Syncing – The run has been finished, and now the system is uploading any outstanding run data \n Completed – All data has been successfully synced, and the run is now completed.",
                        valueClassName:
                          getColor(data?.run?.runStatus) +
                          " runDetailStatusStyle",
                        value: data?.run?.runStatus || "",
                        className: styles.smallPadding,
                      },

                      {
                        title: <Title content="Delay" />,
                        animate: false,
                        tooltip:
                          "This represents the amount of time from the last data update compared to the current time.",
                        value: formatHeartbeatStalenessTime(
                          data?.heartbeatStalenessTime
                        ),
                        valueClassName: styles.smallerWordWrap,
                        statSubtitleClassName: styles.statSubtitleClassName,
                        className: styles.smallPadding,
                      },
                    ]}
                  />

                  <StatCard
                    className="mt-8"
                    gridClassName={styles.statCardContainerTwo}
                    textarea={{
                      note: data?.run?.note,
                      runId,
                    }}
                    textAreaClassName={styles.textArea}
                    // noBokeh
                  />
                </div>
              </div>
            </main>

            <aside>
              <Stack horizontal wrap tokens={{ childrenGap: 12 }}>
                <Stack>
                  <Text variant="xLarge" weight="bold">
                    Select Block
                  </Text>
                  <Select
                    value={selectedSubLocation}
                    onChange={(e) => setSelectedSubLocation(e.target.value)}
                    style={{ width: 200 }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {validSubLocations.map((block) => (
                      <MenuItem value={block.id}>{block.name}</MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack>
                  <Text variant="xLarge" weight="bold">
                    Select Overlay
                  </Text>
                  <Select
                    value={markerType}
                    onChange={(e) => setMarkerType(e.target.value)}
                    style={{ width: 200 }}
                  >
                    <MenuItem value="route-view">Route</MenuItem>
                    <MenuItem value="speed-heatmap">Speed</MenuItem>
                    <MenuItem value="marker-view">Markers</MenuItem>
                    <MenuItem value="spraydensity-heatmap">
                      Weed Pressure
                    </MenuItem>
                    <MenuItem
                      style={{ fontWeight: "bold" }}
                      disabled="disabled"
                    >
                      ------ Ai Results ------
                    </MenuItem>

                    {Object.entries(uniqueAiDiscoveries).map(
                      ([aiDiscoery, count]) => (
                        <MenuItem value={aiDiscoery}>
                          {aiDiscoery.substring(0, 20)} ({count})
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Stack>
                {uniqueAiDiscoveries &&
                  Object.keys(uniqueAiDiscoveries).includes(markerType) && (
                    <Stack>
                      <Text variant="xLarge" weight="bold">
                        AI Results
                      </Text>
                      <Select
                        value={isAiHeatmap}
                        onChange={(e) => setIsAiHeatmap(e.target.value)}
                        style={{ width: 200 }}
                      >
                        <MenuItem value={false}>Markers</MenuItem>
                        <MenuItem value={true}>Heatmap</MenuItem>
                      </Select>
                    </Stack>
                  )}
              </Stack>

              {isLoaded ? (
                <Map
                  id={runId}
                  polygons={polygons}
                  baseUrl={data?.imageDetails?.baseUrl}
                  markers={markers}
                  solenoidOpenEvents={solenoidOpenEvents}
                  route={route}
                  flow={flow}
                  markerType={markerType}
                  activeMarker={activeMarker}
                  setActiveMarker={setActiveMarker}
                  setMarkerType={setMarkerType}
                  isRunOver={data?.run?.endTimeUtc}
                  isAiHeatmap={isAiHeatmap}
                  mapStyle={{ height: "950px", "margin-top": "20px" }}
                />
              ) : null}
            </aside>
          </div>
        </section>
      </div>
    </>
  );
};

export default RunDetails;
