import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import authService from "../../api-authorization/AuthorizeService";
import RequestOverlay from "../../components/RequestOverlay";
import RadioTable from "../../components/RadioTable";
import styles from "./AdminConfigTractors.module.css";

const AdminConfigTractors = () => {
  const [clients, setClients] = useState(null);
  const [devices, setDevices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedTractor, setSelectedTractor] = useState(null);
  const [newTractorName, setNewTractorName] = useState("");
  const [newDefaultLocationId, setNewDefaultLocationId] = useState(null);
  const [
    newDefaultApplicationRateGalAcre,
    setNewDefaultApplicationRateGalAcre,
  ] = useState("");
  const [newTotalBoomWidthFeet, setNewTotalBoomWidthFeet] = useState("");
  const [newMaxSprayWidthFeet, setNewMaxSprayWidthFeet] = useState("");
  const [newHeartbeatFrequencySec, setNewHeartbeatFrequencySec] = useState("");
  const [newAutoShutOffAfterRun, setNewAutoShutOffAfterRun] = useState(true);

  const [formData, setFormData] = useState({
    serialNumber: "",
    cameraName: "",
    deviceType: "",
    isActive: true,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const [client, setClient] = useState("");
  const [tractors, setTractors] = useState([]);
  const [unaffiliatedDevices, setUnaffiliatedDevices] = useState([]);
  const [, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [showAddTractor, setShowAddTractor] = useState(false);
  const [showAddDevice, setShowAddDevice] = useState(false);

  const handleFormChange = (name) => (event) => {
    if (name === "isActive")
      return setFormData({ ...formData, [name]: event.target.checked });
    setFormData({ ...formData, [name]: event.target.value });
  };

  const fetchDevicesAccordingToSelectedTractor = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/all-by-tractor?clientId=${client}&tractorId=${selectedTractor}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const deviceData = await response.json();
      setDevices(deviceData);
    } catch (error) {
      setTimeout(() => {
        toast.error("An unexpected error occured! Please try again later!");
      }, 1000);
    }
  };

  const fetchLocationsAccordingToSelectedClient = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/locations/all?clientId=${client}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const locations = await response.json();
      setLocations(locations);
    } catch (error) {
      setTimeout(() => {
        toast.error("An unexpected error occured! Please try again later!");
      }, 1000);
    }
  };

  const handleSelectTractor = (ids) => {
    setSelectedTractor(ids?.[0]);
  };

  useEffect(() => {
    if (!selectedTractor || selectedTractor === "new") {
      setDevices([]);
      return;
    }
    fetchDevicesAccordingToSelectedTractor();
  }, [selectedTractor]);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const token = await authService.getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
          }
        );
        const clientData = await response.json();
        setClients(clientData);
        setLoading(false);
        setError(false);
      } catch (error) {
        console.log("error", error);
        setTimeout(() => {
          setError(true);
          setLoading(false);
        }, 1000);
      }
    };
    fetchClients();
  }, []);

  const fetchTractors = async () => {
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/all?ClientId=${client}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const tractorData = await response.json();
      setTractors(tractorData);
    } catch (error) {
      toast.error("Communication with server failed. Please reload your page.");
    }
  };

  const fetchUnaffiliatedDevices = async () => {
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/unaffiliated`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const unaffiliatedDevices = await response.json();
      setUnaffiliatedDevices(unaffiliatedDevices);
    } catch (error) {
      toast.error("Communication with server failed. Please reload your page.");
    }
  };

  useEffect(() => {
    fetchTractors();
    fetchUnaffiliatedDevices();
    fetchLocationsAccordingToSelectedClient();
  }, [client]);

  const handleAddDevice = async () => {
    if (!formData.serialNumber)
      return toast.error("Please select the device serial number");
    if (!formData.cameraName) return toast.error("Please add a camera name");
    if (isNaN(formData.flowMeterFrequency))
      return toast.error(
        "Please enter a valid number value for flow meter frequency"
      );
    if (!formData.deviceType)
      return toast.error("Please select the device type");

    const token = await authService.getAccessToken();
    try {
      console.log("request body", {
        clientId: parseInt(client),
        tractorId: parseInt(selectedTractor),
        ...formData,
      });

      formData.isActive = true;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/add`,
        {
          method: "POST",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify({
            clientId: parseInt(client),
            tractorId: parseInt(selectedTractor),
            ...formData,
          }),
        }
      );

      const data = await response.text();

      if (data.includes("duplicate key")) {
        return toast.error("Device with this serial number already exists!");
      }

      const json = JSON.parse(data);

      toast.success("Device added successfully!");
      setFormData({
        serialNumber: "",
        cameraName: "",
        isActive: true,
        deviceType: "",
        flowMeterFrequency: "",
      });
      fetchDevicesAccordingToSelectedTractor();
      fetchUnaffiliatedDevices();
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleAddTractor = async () => {
    if (!newTractorName) return toast.error("Please enter a sprayer name");
    if (!newDefaultLocationId)
      return toast.error("Please select a default location");
    if (!newDefaultApplicationRateGalAcre)
      return toast.error("Please enter a default application rate");
    if (!newMaxSprayWidthFeet)
      return toast.error("Please enter the max spray width");
    if (!newHeartbeatFrequencySec)
      return toast.error("Please enter the heartbeat frequency");
    if (!newTotalBoomWidthFeet)
      return toast.error("Please enter the total boom width");

    const token = await authService.getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/add`,
        {
          method: "POST",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify({
            name: newTractorName,
            clientId: parseFloat(client),
            defaultApplicationRateGalAcre: newDefaultApplicationRateGalAcre,
            defaultLocationId: newDefaultLocationId,
            maxSprayWidthFeet: newMaxSprayWidthFeet,
            heartbeatFrequencySec: newHeartbeatFrequencySec,
            totalBoomWidthFeet: newTotalBoomWidthFeet,
            autoShutOffAfterRun: newAutoShutOffAfterRun,
          }),
        }
      );

      const data = await response.json();
      if (!data.added && data.message.includes("already exists")) {
        return toast.error("Sprayer with this name already exists!");
      } else if (!data.added) {
        return toast.error(
          "Sprayer was not added. Please check your data and try again!"
        );
      }

      toast.success("Sprayer added successfully!");
      setNewTractorName("");
      setNewDefaultApplicationRateGalAcre("");
      setNewDefaultLocationId(0);
      setNewMaxSprayWidthFeet("");
      setNewHeartbeatFrequencySec("");
      setNewTotalBoomWidthFeet("");
      setNewAutoShutOffAfterRun(true);

      fetchTractors();
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    const tm = setTimeout(() => {
      if (!clients) {
      }
    }, 8000);

    return () => clearTimeout(tm);
  }, [clients]);

  const handleRemoveTractor = async (info) => {
    if (
      window.confirm(
        `Are you sure you want to remove sprayer: ${info.row.original.name}?`
      )
    ) {
    } else {
    }
  };

  const handleRemoveDevice = async (info) => {
    if (
      window.confirm(
        `Are you sure you want to remove device: ${info.row.original.serialNumber}?`
      )
    ) {
    } else {
    }
  };

  const columnsTractors = React.useMemo(
    () => [
      {
        id: "select",
        cell: ({ row }) => (
          <div className="px-1">
            <input
              type="radio"
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: "Id",
        accessorKey: "id",
        cell: (info) => info.getValue(),
      },

      {
        header: "Name",
        accessorKey: "name",
        cell: (info) => info.getValue(),
      },

      {
        header: "App Rate Gal/Acre",
        accessorKey: "defaultApplicationRateGalAcre",
        cell: (info) => info.getValue(),
      },

      {
        header: "Boom Width Ft",
        accessorKey: "totalBoomWidthFeet",
        cell: (info) => info.getValue(),
      },

      {
        header: "Max Spray Width Ft",
        accessorKey: "maxSprayWidthFeet",
        cell: (info) => info.getValue(),
      },

      {
        header: "Heartbeat Freq (secs)",
        accessorKey: "heartbeatFrequencySec",
        cell: (info) => info.getValue(),
      },

      {
        header: "Auto Shutoff After Run",
        accessorKey: "autoShutOffAfterRun",
        cell: (info) => info.getValue().toString(),
      },

      {
        accessorKey: "actions",
        cell: (info) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Link
              to={`/admin/sprayers/edit?clientId=${info.row.original.clientId}&tractorId=${info.row.original.id}`}
              style={{ textDecoration: "none" }}
            >
              Edit
            </Link>
            <button
              onClick={() => handleRemoveTractor(info)}
              style={{ marginLeft: "8px" }}
            >
              Remove
            </button>
          </div>
        ),
        header: (props) => <span>Actions</span>,
      },
    ],
    []
  );

  const columnsDevices = React.useMemo(
    () => [
      /* {
              id: "select",
              cell: ({ row }) => (
                <div className="px-1">
                   <input type="radio" 
                   {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                  />
                </div>
              ),
            }, */
      {
        accessorKey: "serialNumber",
        cell: (info) => info.getValue(),
      },

      {
        accessorKey: "cameraName",
        cell: (info) => info.getValue(),
      },

      {
        accessorKey: "flowMeterFrequency",
        cell: (info) => info.getValue(),
      },

      {
        accessorKey: "deviceType",
        cell: (info) =>
          info.getValue() == "Recorder" ? "Recorder" : "Control Unit",
      },

      {
        accessorKey: "isActive",
        cell: (info) => (info.getValue() ? "Active" : "Inactive"),
      },

      {
        accessorKey: "actions",
        cell: (info) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={`/admin/device/${info.row.original.id}`}
              style={{ textDecoration: "none" }}
            >
              Edit
            </Link>
            <button
              onClick={() => handleRemoveDevice(info)}
              style={{ marginLeft: "8px" }}
            >
              Remove
            </button>
          </div>
        ),
        header: (props) => <span>Actions</span>,
      },
    ],
    []
  );

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={!clients}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />

        <section style={{ zIndex: 2 }} className="relative py-12 w-full pt-20">
          <div className="mb-4">
            <label className="pt-4 pb-4 font-extrabold">Client:</label>
            <select
              // disabled={!!runInProgress}
              id="farm-client"
              name="farm-client"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-orange-600 focus-within:ring-1 focus-within:ring-orange-600 mt-1 w-full p-0 sm:text-sm placeholder:text-gray-300"
            >
              <option value="">-Select Client-</option>
              {clients?.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className="
                    grid grid-cols-1 grid-rows-1 gap-4"
          >
            <div className="overflow-x-auto">
              <h1 className="pt-4 pb-1 font-extrabold">Sprayer:</h1>
              {client ? (
                <div className="mb-8">
                  <RadioTable
                    data={tractors}
                    columns={columnsTractors}
                    onSelect={handleSelectTractor}
                  />
                </div>
              ) : (
                <p>Please select a client!</p>
              )}
              {client !== "" && (
                <button
                  className={styles.configbutton}
                  style={{ maxWidth: "fit-content", whiteSpace: "nowrap" }}
                  onClick={() => {
                    setShowAddTractor(!showAddTractor);
                  }}
                >
                  {showAddTractor
                    ? "Hide new sprayer form"
                    : "New sprayer form"}
                </button>
              )}
              {showAddTractor && (
                <div className="flex flex-col">
                  <label className="pt-4 pb-2 font-extrabold">
                    Sprayer Name
                  </label>
                  <input
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    value={newTractorName}
                    onChange={(e) => {
                      setNewTractorName(e.target.value);
                    }}
                  />
                  <label className="pt-4 pb-2 font-extrabold">
                    Default Application Rate Gal / Acre
                  </label>
                  <input
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    value={newDefaultApplicationRateGalAcre}
                    onChange={(e) => {
                      if (!isNaN(e.target.value))
                        setNewDefaultApplicationRateGalAcre(e.target.value);
                    }}
                  />
                  <label className="pt-4 pb-2 font-extrabold">
                    Default Location
                  </label>
                  <select
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    value={newDefaultLocationId}
                    onChange={(e) => setNewDefaultLocationId(e.target.value)}
                  >
                    <option selected value=""></option>
                    {locations?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                  <label className="pt-4 pb-2 font-extrabold">
                    Total Boom Width
                  </label>
                  <input
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    value={newTotalBoomWidthFeet}
                    onChange={(e) => {
                      if (!isNaN(e.target.value))
                        setNewTotalBoomWidthFeet(e.target.value);
                    }}
                  />
                  <label className="pt-4 pb-2 font-extrabold">
                    Max Spray Width
                  </label>

                  <input
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    value={newMaxSprayWidthFeet}
                    onChange={(e) => {
                      if (!isNaN(e.target.value))
                        setNewMaxSprayWidthFeet(e.target.value);
                    }}
                  />

                  <label className="pt-4 pb-2 font-extrabold">
                    Heartbeat Frequency (secs)
                  </label>

                  <input
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    value={newHeartbeatFrequencySec}
                    onChange={(e) => {
                      if (!isNaN(e.target.value))
                        setNewHeartbeatFrequencySec(e.target.value);
                    }}
                  />

                  <span className="flex align-middle">
                    <input
                      type="checkbox"
                      className="h-5 w-5 my-4 mx-1 mr-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      checked={newAutoShutOffAfterRun}
                      onChange={(e) => {
                        setNewAutoShutOffAfterRun(e.target.checked);
                      }}
                    />
                    <label className="pt-4 pb-2 font-extrabold">
                      Auto Shutdown After Run Completion
                    </label>
                  </span>
                  <label className="pt-4 pb-2 font-extrabold">Run Type</label>

                  <div className="flex flex-col justify-center items-left">
                    <button
                      className={styles.configbutton}
                      style={{ maxWidth: "fit-content", whiteSpace: "nowrap" }}
                      onClick={handleAddTractor}
                    >
                      Add New Sprayer
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="overflow-x-auto">
              <h1 className="pt-4 pb-2 font-extrabold">Devices:</h1>
              {selectedTractor ? (
                <div className="mb-8">
                  <RadioTable data={devices} columns={columnsDevices} />
                </div>
              ) : (
                <p>Please select a sprayer!</p>
              )}
              {client !== "" && selectedTractor && (
                <button
                  className={styles.configbutton}
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    setShowAddDevice(!showAddDevice);
                  }}
                >
                  {showAddDevice ? "Hide new device form" : "New device form"}
                </button>
              )}
              {showAddDevice && (
                <div className="flex flex-col">
                  <label className="pt-4 pb-2 font-extrabold">
                    Unaffiliated Devices
                  </label>
                  <select
                    size="5"
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    placeholder="Serial number..."
                    value={formData.serialNumber}
                    onChange={handleFormChange("serialNumber")}
                  >
                    <option selected value></option>
                    {unaffiliatedDevices?.map((unaffiliatedDevice) => (
                      <option
                        key={unaffiliatedDevice}
                        value={unaffiliatedDevice}
                      >
                        {unaffiliatedDevice}
                      </option>
                    ))}
                  </select>
                  <label className="pt-4 pb-2 font-extrabold">
                    Camera Description
                  </label>
                  <input
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    value={formData.cameraName}
                    onChange={handleFormChange("cameraName")}
                  />

                  <label className="pt-4 pb-2 font-extrabold">
                    Flow Meter Frequency
                  </label>
                  <input
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    placeholder=""
                    value={formData.flowMeterFrequency}
                    onChange={handleFormChange("flowMeterFrequency")}
                  />

                  <label className="pt-4 pb-2 font-extrabold">
                    Device Type
                  </label>
                  <select
                    className="w-full rounded border  box-border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:border focus:border-indigo-700 focus:outline-none"
                    placeholder="Device type..."
                    value={formData.deviceType}
                    onChange={handleFormChange("deviceType")}
                  >
                    <option disabled selected value></option>
                    <option value="1">Recorder</option>
                    <option value="2">Control Unit</option>
                  </select>

                  <button
                    className={styles.configbutton}
                    style={{
                      maxWidth: "fit-content",
                      whiteSpace: "nowrap",
                      marginTop: "15px",
                    }}
                    onClick={handleAddDevice}
                  >
                    Add Device
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminConfigTractors;
