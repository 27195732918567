import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";
import AdminConfig from "./components-container/pages/AdminConfig";
import AdminConfigClients from "./components-container/pages/AdminConfigClients";
import AdminConfigClientsEdit from "./components-container/pages/AdminConfigClientsEdit";
import AdminConfigDevicesEdit from "./components-container/pages/AdminConfigDevicesEdit";
import AdminConfigLocationsEdit from "./components-container/pages/AdminConfigLocationsEdit";
import AdminConfigTractorsEdit from "./components-container/pages/AdminConfigTractorsEdit";
import AdminConfigLocations from "./components-container/pages/AdminConfigLocations";
import AdminConfigTractors from "./components-container/pages/AdminConfigTractors";
import AdminConfigDiagnostics from "./components-container/pages/AdminConfigDiagnostics";
import Agronomist from "./components-container/pages/Agronomist";
import ApiAuthorzationRoutes from "./components-container/api-authorization/ApiAuthorizationRoutes";
import AerialSprayDrone from "./components-container/pages/AerialSprayDrone";
import Dashboard from "./components-container/pages/SettingsPages/Dashboard";
import Billing from "./components-container/pages/SettingsPages/Billing";
import ShopSettings from "./components-container/pages/SettingsPages/ShopSettings";
import Performance from "./components-container/pages/SettingsPages/Performance";
import Reporting from "./components-container/pages/SettingsPages/Reporting";
import MyData from "./components-container/pages/SettingsPages/MyData";
import Home from "./components-container/pages/Home";
import MyFarm from "./components-container/pages/MyFarm";
import Network from "./components-container/pages/SettingsPages/Network";
import Notifications from "./components-container/pages/SettingsPages/Notifications";
import RevenueForecast from "./components-container/pages/RevenueForecast";
import RunDetails from "./components-container/pages/RunDetails";
import TechSupport from "./components-container/pages/TechSupport";
import ToDoList from "./components-container/pages/ToDoList";
import TractorDetails from "./components-container/pages/TractorDetails";
import Tractors from "./components-container/pages/Tractors";
import UserManagement from "./components-container/pages/SettingsPages/UserManagement";
import UATV from "./components-container/pages/Automation";
import Weather from "./components-container/pages/Weather";
import Climate from "./components-container/pages/Climate";
import Savings from "./components-container/pages/Savings";

const RouteFallback = () => (
  <LoadingOverlay
    loaderProps={{ color: "green" }}
    visible
    overlayBlur={4}
    overlayOpacity={1}
    id="loading-overlay"
  />
);

const AppRoutes = [
  {
    path: "/",
    index: true,
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Home />
      </Suspense>
    ),
    requireAdmin: true,
    breadcrumb: "Home",
  },
  {
    path: "/home",
    index: true,
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Home />
      </Suspense>
    ),
    requireAuth: true,
    breadcrumb: "Home",
  },
  // {
  //   path: "/precision-spray/add-tractor",
  //   requireAdmin: true,
  //   breadcrumb: "Add Sprayer",
  //   element: (
  //     <Suspense fallback={<RouteFallback />}>
  //       <AddTractor />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/agronomist",
    requireAuth: true,
    breadcrumb: "Agronomist",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Agronomist />
      </Suspense>
    ),
  },
  {
    path: "/aerial-spray-drone",
    requireAuth: true,
    breadcrumb: "Spray Drone",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AerialSprayDrone />
      </Suspense>
    ),
  },
  {
    path: "/command-center",
    requireManager: true,
    breadcrumb: "Command Center",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <MyFarm />
      </Suspense>
    ),
  },
  {
    path: "/savings",
    requireManager: true,
    breadcrumb: "Savings",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Savings />
      </Suspense>
    ),
  },
  {
    path: "/climate",
    requireManager: true,
    breadcrumb: "Climate",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Climate />
      </Suspense>
    ),
  },
  {
    path: "/revenue-forecast",
    requireAuth: true,
    breadcrumb: "Forecast",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <RevenueForecast />
      </Suspense>
    ),
  },
  {
    path: "/precision-spray/:tractor/:tractorId/run-details/:runId/:runIndex",
    requireAuth: true,

    /* breadcrumb: ({ match, ...rest }) => {
      console.log("match", match);
      console.log("rest", rest);
      return <span>Run details</span>;
    }, */

    breadcrumb: ({ match, ...rest }) => {
      return (
        <span className="capitalize">Run Details {match.params.runIndex}</span>
      );
    },

    element: (
      <Suspense fallback={<RouteFallback />}>
        <RunDetails />
      </Suspense>
    ),
  },

  {
    path: "/to-do-list",
    requireAuth: true,
    breadcrumb: "To Do List",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <ToDoList />
      </Suspense>
    ),
  },
  {
    path: "/precision-spray",
    requireAuth: true,
    breadcrumb: "Sprayers",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Tractors />
      </Suspense>
    ),
  },
  {
    path: "/precision-spray/:name/:id",
    requireManager: true,
    // breadcrumb: "Tractor details",
    breadcrumb: ({ match, ...rest }) => {
      return (
        <span className="capitalize">
          {match.params.name.replaceAll("-", " ")}
        </span>
      );
    },
    element: (
      <Suspense fallback={<RouteFallback />}>
        <TractorDetails />
      </Suspense>
    ),
  },
  {
    path: "/network",
    index: true,
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Network />
      </Suspense>
    ),
    requireAuth: true,
    breadcrumb: "Network",
  },
  {
    path: "/tech-support",
    requireAuth: true,
    breadcrumb: "Tech Support",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <TechSupport />
      </Suspense>
    ),
  },
  {
    path: "/settings/dashboard",
    requireManager: true,
    breadcrumb: "Dashboard",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    path: "/settings/my-data",
    requireManager: true,
    breadcrumb: "My Data",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <MyData />
      </Suspense>
    ),
  },
  {
    path: "/settings/performance",
    requireManager: true,
    breadcrumb: "Performance",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Performance />
      </Suspense>
    ),
  },
  {
    path: "/settings/dashboard",
    requireManager: true,
    breadcrumb: "Dashboard",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    path: "/settings/shop-settings",
    requireManager: true,
    breadcrumb: "Shop Settings",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <ShopSettings />
      </Suspense>
    ),
  },
  {
    path: "/settings/billing",
    requireManager: true,
    breadcrumb: "Billing",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Billing />
      </Suspense>
    ),
  },
  {
    path: "/settings/user-management",
    requireManager: true,
    breadcrumb: "User Management",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <UserManagement />
      </Suspense>
    ),
  },
  {
    path: "/settings/network",
    requireManager: true,
    breadcrumb: "Network",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Network />
      </Suspense>
    ),
  },
  {
    path: "/settings/notifications",
    requireManager: true,
    breadcrumb: "Network",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Notifications />
      </Suspense>
    ),
  },
  {
    path: "/settings/reporting",
    requireManager: true,
    breadcrumb: "Reporting",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Reporting />
      </Suspense>
    ),
  },
  {
    path: "/admin",
    requireAdmin: true,
    breadcrumb: "Admin",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfig />
      </Suspense>
    ),
  },
  {
    path: "/admin/clients",
    requireAdmin: true,
    breadcrumb: "Add Client",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigClients />
      </Suspense>
    ),
  },

  {
    path: "/admin/clients/:id",
    requireAuth: true,
    requireAdmin: true,
    breadcrumb: "Edit Client",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigClientsEdit />
      </Suspense>
    ),
  },
  {
    path: "/admin/device/:id",
    requireAuth: true,
    requireAdmin: true,
    breadcrumb: "Edit Device",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigDevicesEdit />
      </Suspense>
    ),
  },
  {
    path: "/admin/locations/:id",
    requireAdmin: true,
    breadcrumb: "Edit Location",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigLocationsEdit />
      </Suspense>
    ),
  },
  {
    path: "/admin/sprayers/edit",
    requireAdmin: true,
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigTractorsEdit />
      </Suspense>
    ),
  },

  {
    path: "/admin/sprayers",
    requireAdmin: true,
    breadcrumb: "Sprayers",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigTractors />
      </Suspense>
    ),
  },

  {
    path: "/admin/diagnostics",
    requireAdmin: true,
    breadcrumb: "Diagnostics",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigDiagnostics />
      </Suspense>
    ),
  },

  {
    path: "/admin/locations",
    requireAdmin: true,
    breadcrumb: "Add Location",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <AdminConfigLocations />
      </Suspense>
    ),
  },
  {
    path: "/automation",
    requireAuth: true,
    breadcrumb: "Automation",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <UATV />
      </Suspense>
    ),
  },
  {
    path: "/weather",
    requireAuth: true,
    breadcrumb: "Weather",
    element: (
      <Suspense fallback={<RouteFallback />}>
        <Weather />
      </Suspense>
    ),
  },
  {
    path: "/undefined",
    element: <Navigate to="/" replace={true} />,
  },
  ...ApiAuthorzationRoutes,
];

export default AppRoutes;
