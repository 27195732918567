import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import toast from "react-hot-toast";
import authService from "../../api-authorization/AuthorizeService";
import RequestOverlay from "../../components/RequestOverlay";
import styles from "./AdminConfig.module.css";
import NavCard from "../../components/NavCard";
import { precisionspray, support } from "../../../images";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
    header: () => <span>Id</span>,
  }),
  columnHelper.accessor("serialNumber", {
    cell: (info) => <i>{info.getValue()}</i>,
    header: () => <span>Serial Number</span>,
  }),
  columnHelper.accessor("cameraName", {
    cell: (info) => <i>{info.getValue()}</i>,
    header: () => <span>Camera Name</span>,
  }),
  columnHelper.accessor("isActive", {
    cell: (info) => <i>{info.getValue() === true ? "Yes" : "No"}</i>,
    header: () => <span>Active</span>,
  }),
];

const Table = ({ data, tractor, client, fetchDevices }) => {
  const [newDevice, setNewDevice] = useState("");
  const [newDeviceCameraName, setNewDeviceCameraName] = useState("");

  const handleAddDevice = async () => {
    if (!newDevice) return toast.error("Please add a device serial number");
    if (!newDeviceCameraName) return toast.error("Please add a camera name");
    const token = await authService.getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/add`,
        {
          method: "POST",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify({
            clientId: client,
            tractorId: tractor,
            serialNumber: newDevice,
            cameraName: newDeviceCameraName,
            // isActive: true,
          }),
        }
      );

      const data = await response.json();
      toast.success("Device added successfully!");
      setNewDevice("");
      setNewDeviceCameraName("");
      fetchDevices();
    } catch (err) {}
  };
  // const [data, setData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="p-2">
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {tractor && tractor !== "new" && (
        <div className="flex flex-col">
          <input
            className="my-8"
            placeholder="Serial number..."
            value={newDevice}
            onChange={(e) => {
              setNewDevice(e.target.value);
            }}
          />

          <input
            className="my-8"
            placeholder="Camera name..."
            value={newDeviceCameraName}
            onChange={(e) => {
              setNewDeviceCameraName(e.target.value);
            }}
          />

          <button onClick={handleAddDevice}>Add Device</button>
        </div>
      )}
    </div>
  );
};

const AdminConfig = () => {
  const [clients, setClients] = useState(null);
  const [devices, setDevices] = useState([]);
  const [newTractorName, setNewTractorName] = useState("");

  const [client, setClient] = useState("");
  const [tractors, setTractors] = useState(null);
  const [tractor, setTractor] = useState("");
  const [, setLoading] = useState(true);
  const [, setError] = useState(false);

  const fetchDevices = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/all-by-tractor?clientId=${client}&tractorId=${tractor}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const deviceData = await response.json();
      setDevices(deviceData);
    } catch (error) {
      setTimeout(() => {
        toast.error("An unexpected error occured! Please try again later!");
      }, 1000);
    }
  };

  useEffect(() => {
    if (!tractor || tractor === "new") {
      setDevices([]);
      return;
    }
    fetchDevices();
  }, [tractor]);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const token = await authService.getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
          }
        );
        const clientData = await response.json();
        setClients(clientData);
        setLoading(false);
        setError(false);
      } catch (error) {
        setTimeout(() => {
          setError(true);
          setLoading(false);
        }, 1000);
      }
    };
    fetchClients();
  }, []);

  const fetchTractors = async () => {
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/all?ClientId=${client}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const tractorData = await response.json();
      setTractors(tractorData);
    } catch (error) {
      toast.error("Communication with server failed. Please reload your page.");
    }
  };

  useEffect(() => {
    if (!client) {
      setTractor("");
      return;
    }

    setTractor("");
    fetchTractors();
  }, [client]);

  const handleAddTractor = async () => {
    if (!newTractorName) return toast.error("Please add a tractor name");
    const token = await authService.getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/add`,
        {
          method: "POST",
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
          body: JSON.stringify({
            name: newTractorName,
            clientId: parseFloat(client),
          }),
        }
      );

      toast.success("Tractor added successfully!");
      setTractor("");
      setNewTractorName("");

      fetchTractors();
    } catch (err) {}
  };

  return (
    <>
      <div>
        <RequestOverlay
          isLoading={!clients}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />

        <div className={styles.gridContainer}>
          <div>
            <NavCard
              title="Clients"
              actionText="Configure"
              icon={support}
              link="/admin/clients"
            />
          </div>
          <div>
            <NavCard
              title="Sprayers"
              actionText="Configure"
              icon={support}
              link="/admin/sprayers"
              // disabled
            />
          </div>
          <div>
            <NavCard
              title="Locations"
              actionText="Configure"
              icon={support}
              link="/admin/locations"
            />
          </div>
          <div></div>
          <div>
            <NavCard
              title="Diagnostics"
              actionText="Configure"
              icon={support}
              link="/admin/diagnostics"
              // disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminConfig;
