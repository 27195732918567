import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  ScrollablePane,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  AddIcon,
  DeleteIcon,
  SaveIcon,
  UndoIcon,
} from "@fluentui/react-icons-mdl2";
import authService from "../../api-authorization/AuthorizeService";
import styles from "./AdminConfigTractorsEdit.module.css";
import AddNozzleDialog from "./AddNozzleDialog/AddNozzleDialog";
import DeleteNozzleDialog from "./DeleteNozzleDialog/DeleteNozzleDialog";

const AdminConfigTractorsEdit = () => {
  const [searchParams] = useSearchParams();
  const [tractor, setTractor] = useState({});
  const [nozzles, setNozzles] = useState([]);
  const [fetchedNozzles, setFetchedNozzles] = useState([]);
  const [nozzleForDeletion, setNozzleForDeletion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [showAddNozzleDialog, { toggle: toggleShowAddNozzleDialog }] =
    useBoolean(false);
  const [showDeleteNozzleDialog, { toggle: toggleShowDeleteNozzleDialog }] =
    useBoolean(false);

  const getClientId = useCallback(
    () => searchParams.get("clientId"),
    [searchParams]
  );
  const getTractorId = useCallback(
    () => searchParams.get("tractorId"),
    [searchParams]
  );

  const fetchTractor = useCallback(async () => {
    setLoading(true);
    try {
      const clientId = getClientId();
      const tractorId = getTractorId();
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractor?clientId=${clientId}&tractorId=${tractorId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const tractor = await response.json();
      setTractor(tractor);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  }, [getClientId, getTractorId]);

  const fetchNozzles = useCallback(async () => {
    setLoading(true);
    try {
      const clientId = getClientId();
      const tractorId = getTractorId();
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzles?clientId=${clientId}&tractorId=${tractorId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      const nozzles = (await response.json()).map((n) => ({
        ...n,
        overlap: Math.round(n.overlap * 100),
      }));
      setFetchedNozzles(nozzles);
      setNozzles(nozzles);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  }, [getClientId, getTractorId]);

  useEffect(() => {
    fetchTractor();
    fetchNozzles();
  }, [getClientId, getTractorId, fetchTractor, fetchNozzles]);

  const _columns = [
    {
      key: "column0",
      fieldName: "rowNumber",
      minWidth: 40,
      maxWidth: 40,
      onRender: (item, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      key: "column1",
      name: "Nozzle GPM",
      fieldName: "nozzleGpm",
      minWidth: 120,
      isResizable: true,
      onRender: (nozzle) => (
        <>
          <TextField
            style={
              hasNozzleValueChanged(nozzle, "nozzleGpm")
                ? { border: "1px solid rgb(164, 38, 44)" }
                : {}
            }
            type="number"
            value={nozzle.nozzleGpm}
            onChange={(e, newValue) =>
              onEditNozzle(nozzle.id, "nozzleGpm", newValue)
            }
          />
        </>
      ),
    },
    {
      key: "column2",
      name: "Spray Width (in)",
      fieldName: "sprayWidth",
      minWidth: 120,
      isResizable: true,
      onRender: (nozzle) => (
        <TextField
          style={
            hasNozzleValueChanged(nozzle, "sprayWidth")
              ? { border: "1px solid rgb(164, 38, 44)" }
              : {}
          }
          type="number"
          value={nozzle.sprayWidth}
          onChange={(e, newValue) =>
            onEditNozzle(nozzle.id, "sprayWidth", newValue)
          }
        />
      ),
    },
    {
      key: "column3",
      name: "Overlap (%)",
      fieldName: "overlap",
      minWidth: 120,
      isResizable: true,
      onRender: (nozzle) => (
        <TextField
          style={
            hasNozzleValueChanged(nozzle, "overlap")
              ? { border: "1px solid rgb(164, 38, 44)" }
              : {}
          }
          type="number"
          value={nozzle.overlap}
          onChange={(e, newValue) =>
            onEditNozzle(nozzle.id, "overlap", newValue)
          }
        />
      ),
    },
    {
      key: "column4",
      name: "Delete",
      minWidth: 120,
      isResizable: true,
      onRender: (nozzle) => (
        <DefaultButton
          hidden={!haveAnyValuesChanged()}
          onClick={() => openDeleteNozzleDialog(nozzle)}
          onRenderIcon={() => <DeleteIcon />}
          text="Delete"
        >
          Delete
        </DefaultButton>
      ),
    },
  ];

  const onEditNozzle = (nozzleId, fieldName, newValue) => {
    newValue = Math.max(0, newValue);
    if (fieldName === "overlap") {
      newValue = Math.min(100, newValue);
    }
    const updatedNozzles = nozzles.map((nozzle) => {
      if (nozzle.id === nozzleId) {
        return { ...nozzle, [fieldName]: parseInt(newValue, 10) };
      }
      return nozzle;
    });
    setNozzles(updatedNozzles);
  };

  const hasNozzleValueChanged = (nozzle, fieldName) => {
    const fetchedNozzle = fetchedNozzles.find(
      (fetchedNozzle) => fetchedNozzle.id === nozzle.id
    );
    return fetchedNozzle[fieldName] !== nozzle[fieldName];
  };

  const haveAnyValuesChanged = () => {
    return _.isEqual(fetchedNozzles, nozzles);
  };

  const handleAddNozzlesSuccess = () => {
    toggleShowAddNozzleDialog();
    fetchNozzles();
  };

  const updateNozzles = async () => {
    const token = await authService.getAccessToken();
    const body = nozzles.map((n) => ({ ...n, overlap: n.overlap / 100 }));
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzles`,
      {
        method: "POST",
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
        body: JSON.stringify(body),
      }
    );
    toast.success("Nozzles updated successfully!");
    fetchNozzles();
  };

  const openDeleteNozzleDialog = (nozzle) => {
    setNozzleForDeletion(nozzle);
    toggleShowDeleteNozzleDialog();
  };

  const closeDeleteNozzleDialog = () => {
    toggleShowDeleteNozzleDialog();
    setNozzleForDeletion(null);
  };

  const handleDeleteNozzleSuccess = () => {
    toggleShowDeleteNozzleDialog();
    fetchNozzles();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.scrollablePaneWrapper}>
          <ScrollablePane>
            <Sticky stickyPosition={StickyPositionType.Header}>
              <Stack>
                <Text variant="xxLarge">{tractor.name}</Text>
              </Stack>
              <Stack horizontal horizontalAlign="space-between" wrap>
                <Stack.Item grow>
                  <Text variant="xLarge">Nozzle Management</Text>
                </Stack.Item>
                <Stack.Item grow hidden={!haveAnyValuesChanged()}>
                  <Stack horizontal horizontalAlign="right">
                    <DefaultButton
                      disabled={loading}
                      onClick={toggleShowAddNozzleDialog}
                      onRenderIcon={() => <AddIcon />}
                      text="Add Nozzles"
                    ></DefaultButton>
                  </Stack>
                </Stack.Item>
                <Stack.Item grow hidden={haveAnyValuesChanged()}>
                  <Stack
                    horizontal
                    horizontalAlign="right"
                    tokens={{ childrenGap: 10 }}
                  >
                    <DefaultButton
                      onClick={updateNozzles}
                      onRenderIcon={() => <SaveIcon />}
                      text="Save Changes"
                    />
                    <DefaultButton
                      onClick={() => {
                        setNozzles(fetchedNozzles);
                      }}
                      onRenderIcon={() => <UndoIcon />}
                      text="Discard Changes"
                    />
                  </Stack>
                </Stack.Item>
              </Stack>
              <hr />
            </Sticky>
            <Stack>
              <DetailsList
                items={nozzles}
                columns={_columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                disableSelectionZone="true"
                selectionMode={SelectionMode.none}
              />
              {showAddNozzleDialog && (
                <AddNozzleDialog
                  clientId={getClientId()}
                  tractorId={getTractorId()}
                  onDismiss={toggleShowAddNozzleDialog}
                  onSuccess={handleAddNozzlesSuccess}
                />
              )}
              {showDeleteNozzleDialog && (
                <DeleteNozzleDialog
                  clientId={getClientId()}
                  nozzleId={nozzleForDeletion.id}
                  onDismiss={closeDeleteNozzleDialog}
                  onSuccess={handleDeleteNozzleSuccess}
                />
              )}
            </Stack>
          </ScrollablePane>
        </div>
      </div>
    </>
  );
};

export default AdminConfigTractorsEdit;
